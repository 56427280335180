import React, { useState } from 'react'
import { parse, isValid } from 'date-fns'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PageSection from '../components/PageSection'
import BewerbungForm from '../components/BewerbungForm'
import {
  initialDataArtwork,
  texts,
} from '../components/BewerbungFormDefinition'

import { firebase, firestore } from '../firebase'
import ApplicationOver from '../components/ApplicationOver'
import appConfig from '../appConfig'
import ApplicationAnnouncement from '../components/ApplicationAnnouncement'
import ApplicationMaintenance from '../components/ApplicationMaintenance'

// eslint-disable-next-line react/prop-types
function Login({ location }) {
  const [formLanguage, setFormLanguage] = useState('de')
  const [searchId, setSearchId] = useState('')
  const [searchFormError, setSearchFormError] = useState(null)
  const [bewerbungInitialData, setBewerbungInitialData] = useState()

  const onSelectChange = (evt) => {
    setFormLanguage(evt.target.value)
  }

  async function fetchHistoricalData(fairs, artistId) {
    for (const fair of fairs) {
        try {
            const artworksQuery = await firestore
                .collection('artworks')
                .where('fair', '==', fair)
                .where('artistId', '==', artistId)
                .get();

            if (!artworksQuery.empty) {
                console.log(`Successfully fetched artworks from ${fair}`);
                return artworksQuery.docs.map(doc => doc.data());
            }
        } catch (error) {
            console.error(`Error fetching data for year ${fair}:`, error);
        }
    }

    // No data found for any year
    return null;
}

  const handleSearchSubmit = async (event) => {
    event.preventDefault()
    // if (!appConfig.showApplicationForSome.includes(searchId)) {
    //   setSearchFormError(texts[formLanguage].radioChoiceError)
    //   return
    // }
    try {
      console.log(searchId)
      const artistQuery = await firestore
        .collection('artists')
        .where('artistId', '==', searchId)
        .get()

      // // check if user was deleted
      // if (artistQuery?.docs[0]?.deleted === true) {
      //   console.log(texts[formLanguage].radioChoiceError)
      //   // setSearchFormError(texts[formLanguage].radioChoiceError)
      // }

      if (!artistQuery.empty && !artistQuery?.docs[0]?.data().deleted) {
        // if all fine, continue fetching artworks
        const resultId = artistQuery.docs[0].id
        const resultData = artistQuery.docs[0].data()
        // console.log(new Date(timestamp.toMillis()).toLocaleDateString('de-DE'))
        const {
          createdAt,
          updatedAt,
          lastupdate,
          updatedby,
          userAgent,
          previewImage,
          birthday,
          ...artistFields
        } = resultData

        if (typeof birthday === 'string') {
          const date_formats = [
            'd.M.yy', 'd.MM.yy', 'd.M.yyyy', 'd.MM.yyyy',
            'dd.M.yy', 'dd.MM.yy', 'dd.M.yyyy', 'dd.MM.yyyy',
          ];
          const parsed = date_formats.map(t => parse(birthday, t, new Date())).find(t => isValid(t));
          artistFields.birthday = parsed || new Date()
          artistFields.parsedBirthday = birthday
        } else if (birthday.seconds) {
          const timestamp = new firebase.firestore.Timestamp(birthday.seconds, birthday.nanoseconds)
          artistFields.birthday = new Date(timestamp.toMillis())
        } else {
          artistFields.birthday = new Date()
        }
        // now try to fetch artworks
        const oldFairs = [appConfig.currentFairYear, '2023-2', '2023-1', '2022-2', '2022-1', '2021-2', '2021-1','2020']
        let artworksData = await fetchHistoricalData(oldFairs, artistQuery.docs[0].id);
        if (!artworksData) {
            console.log('No old data found for any fair');
        }
        
        const sortedArtworks = artworksData
          ?.filter((doc) => !doc.deleted)
          ?.sort((a, b) => a.artworkIndex - b.artworkIndex)
          ?.sort((a, b) => b.fair.localeCompare(a.fair))
          ?.map((doc, idx) => {
            const {
              createdAt,
              updatedAt,
              lastupdate,
              updatedby,
              hidden,
              artistId,
              artworkIndex,
              id,
              ...rest
            } = doc
            return { ...rest, artworkIndex: idx + 1 }
          }) || [initialDataArtwork]

        const newInitialData = {
          ...artistFields,
          id: resultId,
          artworks: sortedArtworks,
        }
        console.info('newInitialData:', newInitialData)
        setSearchFormError(null)
        setBewerbungInitialData(newInitialData)
      } else {
        setSearchFormError(texts[formLanguage].radioChoiceError)
      }
    } catch (err) {
      console.error(err.message)
      setSearchFormError(texts[formLanguage].radioChoiceError)
    }
  }

  // return (
  //   <Layout location={location} showSlider={false}>
  //     <SEO keywords={[`kuboshow`, `login`]} title="Login" />
  //     <PageSection>
  //       <ApplicationAnnouncement
  //         headingDE={texts['de'].bigHeadingLogin}
  //         headingEN={texts['en'].bigHeadingLogin}
  //       />
  //     </PageSection>
  //   </Layout>
  // )

  return (
    <Layout location={location} showSlider={false}>
      <SEO keywords={[`kuboshow`, `login`]} title="Login" />
      <PageSection>
        <div className="row mb-2">
          <div className="col-12 col-md-10">
            <div className="d-flex w-100 justify-content-between">
              <h1 className="text-primary">
                {texts[formLanguage].bigHeadingLogin}
              </h1>
            </div>
          </div>
          <div className="col-auto">
            <select
              className="form-control"
              value={formLanguage}
              onChange={onSelectChange}
            >
              <option value="de">German</option>
              <option value="en">English</option>
            </select>
          </div>
        </div>
        {appConfig.showApplicationMaintenance ? (
          <ApplicationMaintenance />
        ) : appConfig.showApplicationViaLogin ||
          appConfig.showApplicationViaLoginForSome?.length >= 1 ? (
          <>
            <div className="row mb-5">
              <div className="col-12 col-md-8">
                {!bewerbungInitialData ? (
                  <p>{texts[formLanguage].radioChoiceHeadingLogin}</p>
                ) : null}
                <form onSubmit={handleSearchSubmit}>
                  <input
                    className="form-control"
                    type="text"
                    value={searchId}
                    placeholder="Künstler-ID eingeben"
                    onChange={(e) => setSearchId(e.target.value)}
                    disabled={bewerbungInitialData}
                  />
                  {searchFormError && (
                    <p className="text-danger my-1">{searchFormError}</p>
                  )}
                  {!bewerbungInitialData ? (
                    <button className="btn btn-primary mt-2" type="submit">
                      Start
                    </button>
                  ) : (
                    <p className="my-1">
                      {texts[formLanguage].radioChoiceRestartWithOther}
                    </p>
                  )}
                </form>
              </div>
            </div>
            {bewerbungInitialData && (
              <>
                {/* <div className="row mb-5">
                  <div className="col-12 col-md-8">
                    <button
                      className="btn btn-outline-primary btn-lg px-5 py-3 text-uppercase"
                      onClick={() =>
                        navigate('/confirm-contract?artistId=' + searchId)
                      }
                    >
                      <strong>
                        {texts[formLanguage].buttonToContractConfirmation} -&gt;
                      </strong>
                    </button>
                  </div>
                </div> */}
                <BewerbungForm
                  bewerbungInitialData={bewerbungInitialData}
                  formLanguage={formLanguage}
                  loginMode={true}
                />
              </>
            )}
          </>
        ) : appConfig.showApplicationAnnouncement ? (
          <ApplicationAnnouncement
            headingDE={texts['de'].bigHeading}
            headingEN={texts['en'].bigHeading}
          />
        ) : appConfig.showApplicationOver ? (
          <ApplicationOver
            headingDE={texts['de'].bigHeading}
            headingEN={texts['en'].bigHeading}
          />
        ) : null}
      </PageSection>
    </Layout>
  )

  // <img src={ukFlag} className="h-auto w-100" />
}

export default Login
